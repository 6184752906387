import { useGetCareprovider, useGetCareseeker } from "apollo/hooks/queries";
import {
  GetCareseekerAccountsQuery,
  GetReceiverAccountsQuery,
} from "apollo/queries";
import { Result, composeProgress } from "apollo/utils";
import { capitaliseFirst } from "core/model/utils/strings";
import { Account, QueryProgress } from "core/types";
import TextInputField from "ds_legacy/components/TextInputField";
import { ERROR_COLOR, SUCCESS_COLOR } from "ds_legacy/materials/colors";
import { HorizontalLayout } from "ds_legacy/materials/layouts";
import { padding } from "ds_legacy/materials/metrics";
import {
  Caption,
  FONT_SIZE_16,
  Subheading,
  Title,
} from "ds_legacy/materials/typography";
import { useAcpNavigationHandlers } from "dsl/hooks";
import { CheckIcon, XCircleIcon } from "lucide-react";
import { useEffect, useState } from "react";
import {
  FormRenderProps,
  convertModelDefinition,
  valueDef,
} from "react-forms-state";
import { useSearchParams } from "react-router-dom";
import { useTranslations } from "translations";
import { AccountsTable } from "../Accounts";
import { DetailsTable, DetailsTableRow, SearchButton } from "./shared";

export default function GroupPage({ page }: { page: "receiver" | "sender" }) {
  const translations = useTranslations();
  const navigationHandlers = useAcpNavigationHandlers();
  const [search, setSearch] = useSearchParams();
  const [groupId, setGroupId] = useState<number | null>(null);

  const [careseekerQueryProgress, careseeker] = useGetCareseeker(
    groupId ?? undefined,
    {},
    { skip: page !== "sender" || !groupId },
  );
  const [careproviderQueryProgress, careprovider] = useGetCareprovider({
    id: groupId ?? -1,
    queryOptions: { withRoles: true },
    options: { skip: page !== "receiver" || !groupId },
  });

  const queryParamName =
    page === "sender" ? "careseeker_id" : "careprovider_id";
  const groupIdQueryparam = search.get(queryParamName);

  useEffect(() => {
    if (groupIdQueryparam) {
      setGroupId(Number(groupIdQueryparam));
    } else {
      setGroupId(null);
    }
  }, [groupIdQueryparam]);

  return (
    <>
      <HorizontalLayout aligned>
        <FormRenderProps
          formInputValue={{
            group_id: groupId,
          }}
          modelDefinition={convertModelDefinition({
            ...valueDef("group_id"),
          })}
          onSubmit={({ group_id }: { group_id: string }) => {
            if (group_id) {
              setGroupId(Number(group_id));
              search.set(queryParamName, group_id);
              setSearch(search);
            }
          }}
        >
          {({ submit }) => (
            <>
              <TextInputField
                elementName="group_id"
                placeholder={page === "sender" ? "Sender ID" : "Receiver ID"}
              />
              <SearchButton submit={submit} />
            </>
          )}
        </FormRenderProps>
      </HorizontalLayout>
      <GetAccountsQueryPerPage page={page} groupId={groupId}>
        {(accounts, queryProgress) => (
          <Result
            data={{}}
            id="careprovider"
            ErrorComponent={
              <Caption>
                Could not get {page} {groupId}
              </Caption>
            }
            queryProgress={composeProgress([
              queryProgress,
              careseekerQueryProgress,
              careproviderQueryProgress,
            ])}
          >
            {() => (
              <>
                <Title>
                  {capitaliseFirst(page)} {groupId} -{" "}
                  {page === "sender" ? careseeker?.name : careprovider?.name}
                </Title>
                <DetailsTable>
                  <tbody>
                    <DetailsTableRow>
                      <td
                        style={{
                          padding: padding(0),
                        }}
                      >
                        <Subheading>encryption activated for {page}</Subheading>
                      </td>
                      <td
                        style={{
                          padding: padding(0, 4),
                        }}
                      >
                        {(page === "sender" &&
                          careseeker?.seald_encryption_context) ||
                        (page === "receiver" &&
                          careprovider?.seald_encryption_context) ? (
                          <CheckIcon
                            data-testid="success-icon"
                            style={{ color: SUCCESS_COLOR }}
                            size={FONT_SIZE_16}
                          />
                        ) : (
                          <XCircleIcon
                            data-testid="cancel-icon"
                            style={{ color: ERROR_COLOR }}
                            size={FONT_SIZE_16}
                          />
                        )}
                      </td>
                    </DetailsTableRow>
                  </tbody>
                </DetailsTable>
                <AccountsTable
                  accounts={accounts ?? []}
                  {...{ [queryParamName]: groupId }}
                  goToAccount={(accountId) =>
                    navigationHandlers.goToSealdDebug(
                      `account?account_id=${accountId}`,
                    )
                  }
                  pageState={{}}
                  setPageState={() => {}}
                  title={translations.careproviderApp.settings.accounts.title}
                  withAddAccount={false}
                  sealdId={undefined}
                />
              </>
            )}
          </Result>
        )}
      </GetAccountsQueryPerPage>
    </>
  );
}

function GetAccountsQueryPerPage({
  children,
  groupId,
  page,
}: {
  children: (
    accounts: Readonly<Account[]> | undefined,
    queryProgress: QueryProgress,
  ) => React.ReactNode;
  groupId: number | null;
  page: string;
}) {
  if (!groupId) return null;
  return page === "sender" ? (
    <GetCareseekerAccountsQuery careseekerId={groupId}>
      {(accounts, queryProgress) => children(accounts, queryProgress)}
    </GetCareseekerAccountsQuery>
  ) : (
    <GetReceiverAccountsQuery careproviderId={groupId}>
      {(accounts, queryProgress) => children(accounts, queryProgress)}
    </GetReceiverAccountsQuery>
  );
}
