import { Image } from "@nextui-org/react";
import { FocusScope } from "@react-aria/focus";
import clsx from "clsx";
import { APP_CLINIC, DOCUMENT_PRIVACY_POLICY } from "core/consts";
import { getStaticAsset } from "core/model/config";
import { isDemo } from "core/model/utils/featureFlags";
import { green } from "ds/colors/green";
import { Button, Link } from "ds/ui";
import { useExternalLinkLabel } from "ds_legacy/components/LinkV2";
import { getRecareLogoWithNamePath } from "ds_legacy/components/RecareLogo";
import { useLegalDocuments } from "dsl/atoms/LegalDocuments";
import {
  loginPage,
  LoginPageVariants,
} from "dsl/ecosystems/LoginPageV2/classes";
import { CircleCheckBigIcon } from "lucide-react";
import { useTranslations } from "translations";

export type PasswordChangeSuccessPageProps = LoginPageVariants & {
  loginUrl: string;
};

export function PasswordChangeSuccessPage({
  app = APP_CLINIC,
  env,
  loginUrl,
}: PasswordChangeSuccessPageProps) {
  const classes = loginPage({ app, env });
  const translations = useTranslations();
  const { getDocumentUrl } = useLegalDocuments();
  const externalLinkLabel = useExternalLinkLabel();

  return (
    <div className={classes.page()}>
      <div className={classes.container()}>
        <div className={classes.content()}>
          <div className={classes.header()}>
            <Image
              alt="Recare logo"
              src={getStaticAsset(getRecareLogoWithNamePath("master"))}
              width={160}
            />
          </div>
          <div className="flex justify-center">
            <CircleCheckBigIcon
              aria-hidden="true"
              size={64}
              color={green[600]}
            />
          </div>
          <div className={classes.form()}>
            <div className={classes.formContent()}>
              <FocusScope autoFocus>
                <h1
                  className={clsx(
                    classes.title(),
                    "text-center focus:outline-none",
                  )}
                  tabIndex={-1}
                >
                  {isDemo && (
                    <span className="font-normal">
                      {translations.login.loginPage.titleDemo} |{" "}
                    </span>
                  )}
                  {translations.login.passwordChangeSuccess}
                </h1>
              </FocusScope>
              <p className="text-sm">
                {translations.login.passwordResetSuccessfully}
              </p>
              <Button
                as={Link}
                className="mt-3"
                color="primary"
                href={loginUrl}
                id="success_go_to_login"
                role="link"
                size="lg"
                variant="ghost"
              >
                {translations.login.loginPage.logInNow}
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.footer()}>
          <hr className={classes.footerDivider()} />
          <p>
            {translations.login.loginPage.privacyPolicyInfo}{" "}
            <Link
              id="privacy-policy"
              aria-label={externalLinkLabel(
                translations.login.privacyPolicy,
                "pdf",
              )}
              color="primary-dark"
              href={getDocumentUrl(DOCUMENT_PRIVACY_POLICY)}
              size="sm"
              target="_blank"
            >
              {translations.login.privacyPolicy}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
